<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-ticket-account" :title="$t('tickets.page title')">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        :footer-props="{
          'items-per-page-options': [20, 50],
        }"
      >
        <template v-slot:top>
          <div>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-if="meta"
                  v-model="options.project_id"
                  :items="forSelect(meta.projects)"
                  item-text="text"
                  item-value="id"
                  :label="$t('tickets.fields.project')"
                  @change="projectChanged"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6" class="d-lg-flex">
                <div v-if="meta" class="d-none d-sm-flex">
                  <v-checkbox
                    v-for="(status, idx) in statuses"
                    :key="'check' + idx"
                    v-model="options.status"
                    :label="status.text"
                    :value="status.id"
                    class="pe-2 py-2"
                  ></v-checkbox>
                </div>
                <v-select
                  v-if="meta"
                  v-model="options.status"
                  :items="statuses"
                  item-text="text"
                  item-value="id"
                  :label="$t('tickets.fields.status')"
                  class="d-flex d-sm-none"
                  multiple
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          {{ $t("tickets.status." + item.status) }}
        </template>

        <template v-slot:[`item.updated_at`]="{ item }">
          {{
            moment(item.updated_at)
              .lang($i18n.locale)
              .format("LLL")
          }}
        </template>

        <template v-slot:[`item.project_id`]="{ item }">
          {{ meta.projects[item.project_id] }}
        </template>

        <template v-slot:[`item.entries_count`]="{ item }">
          {{ item.new_entries_count }} / {{ item.entries_count }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click.exact="onOpen(item)"
            @click.ctrl="onOpenTab(item)"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import TicketsForm from "./TicketsForm";
import DeleteDialog from "@/components/crud/DeleteDialog";
import moment from "moment";

export default {
  components: {
    TicketsForm,
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/tickets/",
      headers: [
        {
          text: this.$t("tickets.fields.project"),
          align: "start",
          value: "project_id",
        },
        { text: this.$t("tickets.fields.uid"), align: "start", value: "uid" },
        {
          text: this.$t("tickets.fields.updated"),
          align: "start",
          value: "updated_at",
        },
        { text: this.$t("tickets.fields.email"), value: "customer_email" },
        { text: this.$t("tickets.fields.status"), value: "status" },
        {
          text: this.$t("tickets.fields.entries_count"),
          value: "entries_count",
        },
        { text: this.$t("tickets.actions"), value: "actions", sortable: false },
      ],
      project_id: -1,
      autoLoad: false,
      statuses: [
        { id: "new", text: this.$t("tickets.status.new") },
        { id: "pending", text: this.$t("tickets.status.pending") },
        { id: "waiting", text: this.$t("tickets.status.waiting") },
        { id: "closed", text: this.$t("tickets.status.closed") },
      ],
    };
  },
  created() {
    this.options.itemsPerPage = 20;
    this.options.status = ["new", "pending"];
    this.options.project_id =
      this.$store.state.projectId != null ? this.$store.state.projectId : -1;
    this.options.sortBy = ["updated_at"];
    this.options.sortDesc = [true];
    this.loadMeta();
  },
  methods: {
    moment,
    loadMeta(uri = "create/") {
      this.get(this.apiQuery + uri).then((data) => {
        this.meta = data;
        this.meta.projects[-1] = this.$t("projects.select all");
        this.autoLoad = true;
        if (this.options.project_id) {
          this.reloadData();
        }
      })
      .catch((error) => {
        this.alert(this.$t(error.message));
        this.loading = false;
        this.$emit("serverError", error);
      });
    },
    forSelect(obj) {
      const data = [];
      for (const i in obj) {
        data.push({
          id: parseInt(i),
          text: obj[i],
        });
      }
      return data;
    },
    onOpen(item) {
      this.$router.push("/pages/tickets/" + item.uid);
    },
    onOpenTab(item) {
      window.open("/pages/tickets/" + item.uid, "_blank");
    },
    projectChanged(project) {
      this.$store.commit("SET_PROJECT", project);
    },
  },
};
</script>
