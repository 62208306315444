var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-table","fluid":"","tag":"section"}},[_c('base-panel',{attrs:{"icon":"mdi-ticket-account","title":_vm.$t('tickets.page title')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"multi-sort":"","footer-props":{
        'items-per-page-options': [20, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.meta)?_c('v-select',{attrs:{"items":_vm.forSelect(_vm.meta.projects),"item-text":"text","item-value":"id","label":_vm.$t('tickets.fields.project')},on:{"change":_vm.projectChanged},model:{value:(_vm.options.project_id),callback:function ($$v) {_vm.$set(_vm.options, "project_id", $$v)},expression:"options.project_id"}}):_vm._e()],1),_c('v-col',{staticClass:"d-lg-flex",attrs:{"cols":"12","md":"6"}},[(_vm.meta)?_c('div',{staticClass:"d-none d-sm-flex"},_vm._l((_vm.statuses),function(status,idx){return _c('v-checkbox',{key:'check' + idx,staticClass:"pe-2 py-2",attrs:{"label":status.text,"value":status.id},model:{value:(_vm.options.status),callback:function ($$v) {_vm.$set(_vm.options, "status", $$v)},expression:"options.status"}})}),1):_vm._e(),(_vm.meta)?_c('v-select',{staticClass:"d-flex d-sm-none",attrs:{"items":_vm.statuses,"item-text":"text","item-value":"id","label":_vm.$t('tickets.fields.status'),"multiple":""},model:{value:(_vm.options.status),callback:function ($$v) {_vm.$set(_vm.options, "status", $$v)},expression:"options.status"}}):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("tickets.status." + item.status))+" ")]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at) .lang(_vm.$i18n.locale) .format("LLL"))+" ")]}},{key:"item.project_id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.meta.projects[item.project_id])+" ")]}},{key:"item.entries_count",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.new_entries_count)+" / "+_vm._s(item.entries_count)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.onOpen(item)},function($event){if(!$event.ctrlKey){ return null; }return _vm.onOpenTab(item)}]}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }